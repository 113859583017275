<template>
  <div id="field-container">
    <div id="divfield">
      <textarea
        :placeholder="label"
        :id="'field-' + name"
        :class="'field-' + name"
        :name="name"
        v-model="value"
        @input="onInput"
        rows="4"
      ></textarea>
    </div>
    <div id="error">{{ error }}</div>
  </div>
</template>

<script>
export default {
  name: 'custom-textarea',
  props: ['label', 'value', 'type', 'name'],
  emits: ['input'],
  data() {
    return {
      error: '',
    };
  },
  methods: {
    onInput(event) {
      const value = event.target.value;

      /*
      // PER ORA NON GESTISCO GLI ERRORI LATO CUSTOM INPUT
      this.error = '';

      if (!value || value.trim() == '') {
        this.error = 'Value should not be empty';
        return;
      }
      */
      this.$emit('input', value);
    },
  },
  computed: {
    name2() {
      return this.label.toLowerCase().replaceAll(' ', '-');
    },
  },
};
</script>

<style scoped>
#field-container {
  margin-top: 10px;
}

#label {
  width: 100%;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5px;
  text-transform: uppercase;
}

textarea {
  width: 100%;
  padding: 15px;
  border: none;
  color: #333;
  font-size: 16px;
  font-family: 'Barlow', sans-serif;
  box-sizing: border-box;
  border-radius: 16px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  outline: none;
}

textarea::placeholder {
  color: #666;
}

textarea::first-letter {
  text-transform: uppercase;
}
</style>
